import React from "react"

import Layout from "../components/layout"

const NotFoundPage = () => (
  <Layout>
    <div className="h-screen text-center pt-52">
      <h1>NOT FOUND</h1>
      <p>Sorry, this page could not be found</p>
    </div>
  </Layout>
)

export default NotFoundPage
